import { useLayoutEffect } from 'react'

// * use on a page with a div that has an ID of 'pr-write' to show the write a review widget
// * use on a page with a div that has an ID of 'pr-reviewdisplay' to show reviews widget
// * use on a page with a div that has an ID of 'pr-reviewsnippet' to show stars widget
export default function usePowerReviews(
  pageId: string | null,
  isWriteSnippet?: boolean = false
) {
  const components = isWriteSnippet
    ? {
        Write: 'pr-write',
      }
    : {
        ReviewDisplay: 'pr-reviewdisplay',
        ReviewSnippet: 'pr-reviewsnippet',
      }
  const renderReviews = () => {
    window.POWERREVIEWS?.display.render({
      api_key: process.env.GATSBY_POWER_REVIEWS_API_KEY,
      locale: 'en_US',
      merchant_group_id: '80498',
      merchant_id: '359812',
      page_id: pageId,
      review_wrapper_url: `https://www.rachio.com/write-a-review/?pr_page_id=pr-reviewdisplay`,
      components: components,
    })
  }

  const setReviewsScript = () => {
    const script = document.createElement('script')
    script.setAttribute('src', '//ui.powerreviews.com/stable/4.0/ui.js')
    document['body'].appendChild(script)
    setTimeout(renderReviews, 600)
  }

  useLayoutEffect(() => {
    if (!pageId) return null
    if (window.POWERREVIEWS) {
      return renderReviews()
    } else {
      return setReviewsScript()
    }
  }, [])
}
