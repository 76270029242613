import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { SkipLink } from "../components/Button"
import Header from "../components/nav/Header"
import Footer from "../components/nav/Footer"
import { ContextProvider } from "../global/state"
import { ShopifyCart } from "../components/shopify"

import "../styles/reset.css"
import "../styles/vars.css"
import "../styles/colors.css";
import "../styles/global.css"
import "../styles/classes.css"
import "../styles/overrides.css"

type Props = { children: React.ReactNode }

const Layout = ({ children }: Props) => {
  const [proDiscountId, setDiscountId] = useState("")

  const checkForDiscount = () => {
    const dId = children?.props?.location?.search.split("?discount=")[1]

    if (dId) {
      Cookies.set("proDiscountId", dId, { expires: 3 })
      setDiscountId(dId)
    } else if (Cookies.get("proDiscountId")) {
      setDiscountId(Cookies.get("proDiscountId"))
    }
  }

  useEffect(() => {
    checkForDiscount()
  }, [])

  return (
    <ContextProvider>
      <SkipLink href="#main">Skip to content</SkipLink>
      <Header />
      <main id="main">{children}</main>
      <Footer />
      <ShopifyCart proDiscountId={proDiscountId} />
    </ContextProvider>
  )
}

export default Layout
