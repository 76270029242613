import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

type Item = {
  link: string
  image: IGatsbyImageData
  name: string
}

type Props = {
  items: Item[]
  variants: object
}

const DropdownMenu = ({ items, variants }: Props) => {
  return (
    <Dropdown
      className="dropdown-menu"
      aria-label="submenu"
      variants={variants}
    >
      <ul>
        {items.map(item => (
          <li key={item.name}>
            {item.isExternalLink ? (
              <a href={item.link}>
                <p css="margin: 0 0 0 1rem">{item.name}</p>
              </a>
            ) : (
              <Link to={item.link} css="display: flex">
                {item.image && (
                  <div
                    className="img-wrapper"
                    css="width: 94px; height: 71px; "
                  >
                    <GatsbyImage
                      image={getImage(item.image) as IGatsbyImageData}
                      alt={item.name}
                      style={{ background: "var(--primaryColor)" }}
                    />
                  </div>
                )}
                <p css="margin: 0 0 0 1rem">{item.name}</p>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}

export default DropdownMenu

const Dropdown = styled(motion.div)`
  background: transparent;
  position: absolute;
  width: 40rem;
  left: 0;
  display: none;
  ul {
    background: var(--white);
    margin-top: 4.1rem;
    padding: 2rem;
    box-shadow: var(--elevation-1);
  }
  li {
    clear: both;
    width: 100%;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`
