import React, { useLayoutEffect } from "react"

const Rebate = () => {
  useLayoutEffect(() => {
    if (!window._ecr) {
      const script = document.createElement("script")
      script.setAttribute("src", "/rebate.js")
      document["body"].appendChild(script)
    }
  }, [])
  return (
    <div
      id="ecorebates"
      css={`
        display: none;

        &#ecorebates .container-fluid {
          padding: 0;
        }
        &#ecorebates .eco-m .h1,
        .eco-m h1 {
          display: none;
        }
        &#ecorebates
          .eco-m
          .eco-c
          .eco-product-list
          .eco-product
          > .panel-heading,
        &#ecorebates
          .eco-m
          .eco-c
          .eco-product-list
          .panel-group
          .panel-heading,
        &#ecorebates .eco-m .eco-c .eco-rebate-filter,
        &#ecorebates .eco-m .eco-c .eco-product-list .eco-rebate-list,
        &#ecorebates .eco-m .eco-c .eco-heading:last-child {
          border-color: var(--lightestGray);
        }
        &#ecorebates .eco-m .eco-c .eco-rebate-filter {
          border-left: 1px solid var(--lightestGray);
          border-right: 1px solid var(--lightestGray);
        }
        &#ecorebates
          .eco-m
          .eco-c
          .eco-product-list
          .eco-product
          + .eco-product {
          border-top: 0px;
        }
      `}
    />
  )
}

export default Rebate
