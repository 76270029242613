import React, { SyntheticEvent, useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import Icon from "../Icon"
import { Link } from "gatsby"
import { Item, LinkType } from "./MainNav"
import { scrollTo } from "../../utils/helpers"

type Props = {
  links: LinkType[]
  closeMenu: () => void
}

type NavLinkProps = {
  link: LinkType,
  openSlider: (items: Item[]) => any,
  closeMenu: () => any
};
const NavLink = ({ link, openSlider, closeMenu }: NavLinkProps) => {
  if (link.isExternalLink) {
    <li className="menu-item">
      <a href={link.link}>{link.title}</a>
    </li>
  }

  if (link.scrollTo) {
    const handleScroll = (event: SyntheticEvent) => {
      event.preventDefault();
      closeMenu();
      scrollTo(link.scrollTo as string)
    }

    return (
      <li className="menu-item">
        <a href={link.link} onClick={handleScroll}>
          {link.title}
        </a>
      </li>
    )
  }

  return (
    <li
      className={`menu-item ${link.items ? `has-dropdown` : ""}`}
    >
      {link.items ? (
        <span onClick={() => openSlider(link.items as Item[])}>
          {link.title}
          <Icon
            name="caret"
            color="var(--primaryColor)"
            style={{
              transition: "0.3s ease-in-out transform",
              transform: "rotate(-90deg)",
            }}
          />
        </span>
      ) : (
        <Link
          to={link.link}
          onClick={closeMenu}
          aria-label={link.title}
        >
          {link.title}
        </Link>
      )}
    </li>
  )
}

const MobileNav = ({ links, closeMenu }: Props) => {
  const [sliderItems, setSliderItems] = useState<Item[]|null>(null)
  const openSlider = (items: Item[]) => () => {
    setSliderItems(items as Item[])
  }
  const closeSlider = () => {
    setSliderItems(null)
  }
  return (
    <Nav>
      <AnimatePresence>
        {sliderItems ? (
          <Slider
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            exit={{ x: 400 }}
            transition={{ duration: 0.3 }}
          >
            <div
              className="back"
              onClick={closeSlider}
              css="display: flex;align-items: center;border-bottom: 1px solid var(--lightestGray)"
            >
              <Icon
                name="caret"
                color="var(--primaryColor)"
                style={{
                  transition: "0.3s ease-in-out transform",
                  transform: "rotate(90deg)",
                  marginRight: "1rem",
                }}
              />
              <span>Back</span>
            </div>
            <ul>
              {sliderItems.map(item => {
                return (
                  <li key={item.link} className="menu-item">
                    <Link
                      to={item.link}
                      onClick={closeMenu}
                      aria-label={item.name}
                    >
                      {item.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Slider>
        ) : (
          <motion.ul
            initial={{ x: -400 }}
            animate={{ x: 0 }}
            exit={{ x: -400 }}
            transition={{ duration: 0.3 }}
          >
            {links.map(link => (
              <NavLink
                link={link}
                openSlider={openSlider}
                closeMenu={closeMenu}
                key={link.link}
              />
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </Nav>
  )
}

export default MobileNav

const Nav = styled.nav`
  .menu-item {
    border-bottom: 1px solid var(--lightestGray);
    &.has-dropdown span {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a,
    span {
      padding: 3rem 0;
      display: block;
    }
  }
`

const Slider = styled(motion.div)`
  .back {
    cursor: pointer;
    padding: 3rem 0;
    display: block;
  }
`
