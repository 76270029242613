import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { Card } from "../styles"
import Icon from "./Icon"
import Modal from "./Modal"
import Rebate from "./Rebate"

type Item = {
  name: string
  isExternalLink?: boolean
  link: string
}

type Props = {
  title: string
  isMenuOpen: boolean
  onClick: () => void
  items: Item[]
}

const Accordion = ({ isMenuOpen, onClick, items, title }: Props) => {
  const [isActive, setIsActive] = useState(false)

  const variants = {
    expanded: () => ({
      height: "auto",
      opacity: 1,
    }),
    collapsed: () => ({
      height: 0,
      opacity: 0,
    }),
  }

  return (
    <AccordionWrapper isMenuOpen={isMenuOpen}>
      <div className="accordion-wrapper" onClick={onClick}>
        <h6>{title}</h6>
        <Icon
          name="caret"
          color="var(--primaryBlue)"
          style={{
            transform: isMenuOpen && "rotate(-180deg)",
            transition: "0.3s ease-in-out transform",
          }}
        />
      </div>
      <motion.ul
        initial={isMenuOpen ? "expanded" : "collapsed"}
        animate={isMenuOpen ? "expanded" : "collapsed"}
        variants={variants}
      >
        {items.map((item: Item) => (
          <li key={item.name}>
            {item.isRebate ? (
              <>
                <button
                  onClick={() => setIsActive(true)}
                  css={`
                    &:hover {
                      color: var(--primaryBlue);
                    }
                  `}
                >
                  {item.name}
                </button>
                <Modal
                  isActive={isActive}
                  closeAction={() => setIsActive(false)}
                  maxWidth="95%"
                >
                  <Card css="background: var(--white)">
                    <Rebate />
                  </Card>
                </Modal>
              </>
            ) : (
              <>
                {item.isExternalLink ? (
                  <a href={item.link}>{item.name}</a>
                ) : (
                  <Link to={item.link}>{item.name}</Link>
                )}
              </>
            )}
          </li>
        ))}
      </motion.ul>
    </AccordionWrapper>
  )
}

export default Accordion

const AccordionWrapper = styled.div`
  border-bottom: 1px solid var(--lightestGray);
  .accordion-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 2rem 0;
    cursor: pointer;
    margin: 0;
    h6 {
      margin: 0;
    }
  }
  ul {
    overflow: hidden;
    li {
      padding: 2rem 0;
      display: block;
      a {
        padding: 0;
        display: inline-block;
      }
    }
  }
  ${({ isMenuOpen }: { isMenuOpen: boolean }) =>
    isMenuOpen &&
    `
      h6 {
        color: var(--primaryColor);
      }
    `};
`
