import React from "react"

const Logo = ({ height = "30px", style = {} }) => (
  <svg
    id="rachio-pro-logo"
    data-name="Rachio Pro Logo"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="rachio-pro-logo"
    viewBox="0 0 300.91 50"
    style={{
      height,
      ...style,
    }}
  >
    <defs></defs>
    <title id="rachio-pro-logo">Rachio Pro Logo</title>
    <circle fill="#fff" cx="24.73" cy="25.27" r="24.73" />
    <g id="SPLINE">
      <path
        fill="#022a3a"
        d="M134.9,12.78l1.1-.37a21.72,21.72,0,0,1,8-1.08,11.93,11.93,0,0,1,5.39,1.46,10.36,10.36,0,0,1,4.85,6.32,17.33,17.33,0,0,1,.68,5q0,8.85,0,17.7c0,.08,0,.16,0,.24s-.07.23-.23.23h-5c-.45,0-.46,0-.46-.48q0-8.95,0-17.89A15.92,15.92,0,0,0,149,21.2,5,5,0,0,0,145,17a9.37,9.37,0,0,0-2.31-.32,20.27,20.27,0,0,0-7.39,1.3c-.48.17-.41.11-.41.61q0,11.55,0,23.1c0,.7.08.62-.6.62h-4.54c-.49,0-.49,0-.49-.5q0-10.38,0-20.76,0-10.12,0-20.23c0-.66-.12-.63.6-.63h4.83c.14,0,.2.06.2.2s0,.26,0,.38q0,5.72,0,11.43Z"
      />
    </g>
    <g id="HATCH">
      <path
        fill="#022a3a"
        d="M134.9,12.78l1.1-.37a21.72,21.72,0,0,1,8-1.08,11.93,11.93,0,0,1,5.39,1.46,10.36,10.36,0,0,1,4.85,6.32,17.33,17.33,0,0,1,.68,5q0,8.85,0,17.7c0,.08,0,.16,0,.24s-.07.23-.23.23h-5c-.45,0-.46,0-.46-.48q0-8.95,0-17.89A15.92,15.92,0,0,0,149,21.2,5,5,0,0,0,145,17a9.37,9.37,0,0,0-2.31-.32,20.27,20.27,0,0,0-7.39,1.3c-.48.17-.41.11-.41.61q0,11.55,0,23.1c0,.7.08.62-.6.62h-4.54c-.49,0-.49,0-.49-.5q0-10.38,0-20.76,0-10.12,0-20.23c0-.66-.12-.63.6-.63h4.83c.14,0,.2.06.2.2s0,.26,0,.38q0,5.72,0,11.43Z"
      />
    </g>
    <path
      fill="#022a3a"
      d="M79.77,17.12l.24-.06a23,23,0,0,1,7.92-.78A10.63,10.63,0,0,1,91,17a5.11,5.11,0,0,1,2.87,2.79,10.28,10.28,0,0,1,.74,3.34c0,.28,0,.3-.3.26A36.72,36.72,0,0,0,90.07,23a26.89,26.89,0,0,0-7,.62A13.41,13.41,0,0,0,77.88,26a7.62,7.62,0,0,0-2.78,4,9.71,9.71,0,0,0,1.49,8.45,7.53,7.53,0,0,0,2.81,2.47,15.25,15.25,0,0,0,3.76,1.37,21.55,21.55,0,0,0,5.8.41,17.14,17.14,0,0,0,5.31-1.08A8.52,8.52,0,0,0,97.2,40,9.51,9.51,0,0,0,100.27,33c0-3.09,0-6.19,0-9.28a15.61,15.61,0,0,0-.49-3.93,10.49,10.49,0,0,0-6.93-7.62A15.82,15.82,0,0,0,88,11.33a26.63,26.63,0,0,0-6.7.56q-.76.16-1.51.37s-.49.12-.49.36v4.33S79.29,17.26,79.77,17.12Zm14.86,13.6c0,.72,0,1.43,0,2.15a5.07,5.07,0,0,1-.35,1.87,4,4,0,0,1-2,2.13,7.68,7.68,0,0,1-2.61.73,15.88,15.88,0,0,1-5-.17,7.43,7.43,0,0,1-2.06-.74,4.57,4.57,0,0,1-2-5.36,4.18,4.18,0,0,1,1.59-2,6.3,6.3,0,0,1,2-.94,18.85,18.85,0,0,1,6.83-.82c1.14.08,2.29.14,3.42.3.24,0,.32.12.31.36C94.62,29,94.63,29.88,94.63,30.72Z"
    />
    <path
      fill="#022a3a"
      d="M195.65,25a19.35,19.35,0,0,0-1-5.19,11.14,11.14,0,0,0-3.37-5.23A13.78,13.78,0,0,0,186.25,12a19.27,19.27,0,0,0-7.15-.5,15,15,0,0,0-5.71,1.77,10.86,10.86,0,0,0-5,5.67,19.78,19.78,0,0,0-1.31,6,28.91,28.91,0,0,0,.08,5.2,18.11,18.11,0,0,0,1.18,5,10.63,10.63,0,0,0,3.14,4.49,13.88,13.88,0,0,0,4.34,2.37,18.74,18.74,0,0,0,7.84.71,14.57,14.57,0,0,0,7.15-2.71,9.22,9.22,0,0,0,2-2,13.27,13.27,0,0,0,1.91-3.73,22.93,22.93,0,0,0,1-7.15C195.75,26.39,195.69,25.71,195.65,25Zm-6.21,7.45-.11.37A6.43,6.43,0,0,1,184,37.5a13.72,13.72,0,0,1-5.6-.07,6.32,6.32,0,0,1-4.84-4.1,13.83,13.83,0,0,1-.75-3.35,28.33,28.33,0,0,1-.09-4.82,16.07,16.07,0,0,1,.73-4.08,6.29,6.29,0,0,1,4.79-4.35,13.06,13.06,0,0,1,6.48.05,6.21,6.21,0,0,1,4.46,4,13.88,13.88,0,0,1,.74,3.2,29.38,29.38,0,0,1,.17,3.15A20.7,20.7,0,0,1,189.44,32.48Z"
    />
    <g id="SPLINE-2" data-name="SPLINE">
      <path
        fill="#022a3a"
        d="M126.15,18.27a.6.6,0,0,1-.42-.11,18.83,18.83,0,0,0-5.61-1.65,14.06,14.06,0,0,0-6.38.44,8.71,8.71,0,0,0-1.39.63,6.07,6.07,0,0,0-2.87,3.5,14.65,14.65,0,0,0-.66,3.12,27.86,27.86,0,0,0-.09,4.54,15.2,15.2,0,0,0,.82,4.4,6.55,6.55,0,0,0,5,4.29,15.59,15.59,0,0,0,5.38.15,20,20,0,0,0,5.9-1.67c.07,0,.14-.09.23-.06s0,.24,0,.36q0,2.22,0,4.45c0,.43,0,.44-.39.58a24.86,24.86,0,0,1-5.94,1.39,22.11,22.11,0,0,1-5.86-.1,12.07,12.07,0,0,1-10.3-9,20.51,20.51,0,0,1-.8-4.75,27,27,0,0,1,.22-5.63,15.85,15.85,0,0,1,1.75-5.48,11.76,11.76,0,0,1,7.91-5.91,20.45,20.45,0,0,1,6.27-.41,23.9,23.9,0,0,1,6.7,1.46c.49.18.45.07.45.63q0,2.18,0,4.35C126.15,18,126.15,18.11,126.15,18.27Z"
      />
    </g>
    <g id="HATCH-2" data-name="HATCH">
      <path
        fill="#022a3a"
        d="M126.15,18.27a.6.6,0,0,1-.42-.11,18.83,18.83,0,0,0-5.61-1.65,14.06,14.06,0,0,0-6.38.44,8.71,8.71,0,0,0-1.39.63,6.07,6.07,0,0,0-2.87,3.5,14.65,14.65,0,0,0-.66,3.12,27.86,27.86,0,0,0-.09,4.54,15.2,15.2,0,0,0,.82,4.4,6.55,6.55,0,0,0,5,4.29,15.59,15.59,0,0,0,5.38.15,20,20,0,0,0,5.9-1.67c.07,0,.14-.09.23-.06s0,.24,0,.36q0,2.22,0,4.45c0,.43,0,.44-.39.58a24.86,24.86,0,0,1-5.94,1.39,22.11,22.11,0,0,1-5.86-.1,12.07,12.07,0,0,1-10.3-9,20.51,20.51,0,0,1-.8-4.75,27,27,0,0,1,.22-5.63,15.85,15.85,0,0,1,1.75-5.48,11.76,11.76,0,0,1,7.91-5.91,20.45,20.45,0,0,1,6.27-.41,23.9,23.9,0,0,1,6.7,1.46c.49.18.45.07.45.63q0,2.18,0,4.35C126.15,18,126.15,18.11,126.15,18.27Z"
      />
    </g>
    <g id="SPLINE-3" data-name="SPLINE">
      <path
        fill="#022a3a"
        d="M61.17,28.28q0-6.72,0-13.44c0-.57-.06-.47.47-.67a42.31,42.31,0,0,1,9-2.44,32.34,32.34,0,0,1,5-.41c.42,0,.43,0,.43.4q0,2.18,0,4.35c0,.38,0,.39-.4.39a37.82,37.82,0,0,0-8.4,1.16c-.46.11-.46.11-.46.59q0,11.72,0,23.43c0,.74.09.64-.62.65H61.4c-.16,0-.23-.07-.23-.23s0-.25,0-.38Q61.17,35,61.17,28.28Z"
      />
    </g>
    <g id="HATCH-3" data-name="HATCH">
      <path
        fill="#022a3a"
        d="M61.17,28.28q0-6.72,0-13.44c0-.57-.06-.47.47-.67a42.31,42.31,0,0,1,9-2.44,32.34,32.34,0,0,1,5-.41c.42,0,.43,0,.43.4q0,2.18,0,4.35c0,.38,0,.39-.4.39a37.82,37.82,0,0,0-8.4,1.16c-.46.11-.46.11-.46.59q0,11.72,0,23.43c0,.74.09.64-.62.65H61.4c-.16,0-.23-.07-.23-.23s0-.25,0-.38Q61.17,35,61.17,28.28Z"
      />
    </g>
    <g id="SPLINE-4" data-name="SPLINE">
      <path
        fill="#022a3a"
        d="M158.49,27q0-7.34,0-14.68c0-.6-.12-.58.6-.58h4.78c.19,0,.25.09.24.26s0,.22,0,.33q0,14.68,0,29.37c0,.64.08.58-.6.58h-4.4c-.73,0-.62.08-.62-.65Q158.48,34.32,158.49,27Z"
      />
    </g>
    <g id="HATCH-4" data-name="HATCH">
      <path
        fill="#022a3a"
        d="M158.49,27q0-7.34,0-14.68c0-.6-.12-.58.6-.58h4.78c.19,0,.25.09.24.26s0,.22,0,.33q0,14.68,0,29.37c0,.64.08.58-.6.58h-4.4c-.73,0-.62.08-.62-.65Q158.48,34.32,158.49,27Z"
      />
    </g>
    <circle fill="#022a3a" cx="161.24" cy="3.55" r="3.55" />
    <path
      fill="#00a8e1"
      d="M24.73.54a24.73,24.73,0,0,0-3.8,49.16V22.39c0-.9-.75-1.12-1.79-1.33h0a26.42,26.42,0,0,0-5.28-.63h-.44V16.3A26.77,26.77,0,0,1,22,17.49h0V11.74C25.09,10.26,31.08,10,31.08,10v3.27h-.35a19.89,19.89,0,0,0-3.65.39,1.93,1.93,0,0,0-1.69,1.76v9.95C32.17,23.55,36,23.91,36,23.91V29h-.55a33.84,33.84,0,0,0-7.11.91,2,2,0,0,0-1.78,1.78v18.2A24.72,24.72,0,0,0,24.73.54Z"
    />
    <path
      fill="#00a8e1"
      d="M207.36,11.74h14.57c5.72,0,11.09,2.62,11.09,9.88,0,7.6-4.34,10.57-11.09,10.57h-6.57V42.42h-8Zm8,14.27h5.37c2.49,0,4.3-1,4.3-3.82s-1.85-4-4.3-4h-5.37Z"
    />
    <path
      fill="#00a8e1"
      d="M237.48,11.74h15.64c5.41,0,10.74,2.45,10.74,8.64,0,3.31-1.59,6.44-4.81,7.69v.09c3.27.77,4.21,4.43,4.47,7.35.09,1.29.21,5.8,1.29,6.92H256.9c-.69-1-.82-4-.9-4.9-.21-3.09-.73-6.27-4.47-6.27h-6.06V42.42h-8Zm8,13.32h6.7c2.41,0,3.7-1.29,3.7-3.61s-1.81-3.31-4-3.31h-6.36Z"
    />
    <path
      fill="#00a8e1"
      d="M282.42,11.09c9.67,0,15.13,7.09,15.13,16s-5.46,16-15.13,16S267.3,36,267.3,27.08,272.75,11.09,282.42,11.09Zm0,25.09c5.16,0,7.13-4.56,7.13-9.11s-2-9.11-7.13-9.11-7.13,4.56-7.13,9.11S277.27,36.19,282.42,36.19Z"
    />
    <path fill="#00a8e1" d="M296.21,11.72V11.3h1.92v.42h-.75v1.92H297V11.72Z" />
    <path
      fill="#00a8e1"
      d="M298.62,13.65V11.3h.47l.68,1,.68-1h.47v2.35h-.42V11.91l-.72,1-.72-1v1.73Z"
    />
  </svg>
)

export default Logo
