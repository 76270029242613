import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button } from "../Button"
import { below, Flex } from "../../styles"
import { useScrollFreeze } from "../../hooks"
import { motion, AnimatePresence } from "framer-motion"

import CartItem from "./CartItem"
import { useCheckout } from "../../global"
import Icon from "../Icon"
import Portal from "../Portal"
import LightBar from "../LightBar"
import { LineItem, Cart } from "shopify-buy"
import { AuthContext } from "@rachio-npm/rachio-retail-common"

type Props = {
  proDiscountId?: string
}

const ShopifyCart = ({ proDiscountId = "" }: Props) => {
  const {
    checkout,
    isCartOpen,
    setIsCartOpen,
    isCheckoutLoading,
    setIsCheckoutLoading,
  } = useCheckout();

  if (!checkout) return null

  // Gets Google Analytics linker param for cross-domain tracking
  const getLinkerParam = () => {
    let linkerParam = "?"
    if (window.ga) {
      window.ga(ga => {
        linkerParam += ga.get("linkerParam")
      })
      return linkerParam
    }
    return ""
  }

  const transformParams = (url: string) => {
    const idx = url.indexOf("?") + 1
    const preQuery = url.slice(0, idx)
    const postQuery = url.slice(idx)
    const replaced = postQuery.replace(/\?/g, "&")
    return preQuery + replaced
  }

  const openCheckout = () => {
    setIsCheckoutLoading(true)
    const linkerParam = getLinkerParam()
    const discountVal = proDiscountId ? `&discount=${proDiscountId}` : ""
    window.location.href = transformParams(
      `${checkout.webUrl}${linkerParam}${discountVal}`
    )
  }

  const closeCart = () => setIsCartOpen(false)

  return (
    <Portal>
      <AnimatePresence>
        {isCartOpen && (
          <CartModal
            isCartOpen={isCartOpen}
            closeCart={closeCart}
            checkout={checkout}
            openCheckout={openCheckout}
            isCheckoutLoading={isCheckoutLoading}
          />
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default ShopifyCart

type NotLoggedInProps = {
  closeCart: () => void
}

const NotLoggedIn = ({ closeCart }: NotLoggedInProps) => {
  return (
    <div style={{ marginBottom: '2rem' }} data-testid="cart-not-logged-in">
      <p
        style={{ textAlign: 'center' }}
        data-testid="cart-title"
      >
        You're not currently logged in. Log in to purchase products.
      </p>
      <Button
        as={Link}
        onClick={closeCart}
        css="width: 100%; margin-bottom: 2rem"
        to="/buy/"
        data-testid="cart-login-button"
      >
        Login
      </Button>
    </div>
  )
}

type CartContentProps = {
  isCartEmpty: boolean
  closeCart: () => void
  checkout: Cart
  openCheckout: any
  isCheckoutLoading: boolean
}
const CartContent = ({
  isCartEmpty,
  closeCart,
  checkout,
  openCheckout,
  isCheckoutLoading
}: CartContentProps) => {
  return (
    <div css="margin-bottom: 3rem" data-testid="cart-content">
      {isCartEmpty && (
        <>
          <p data-testid="empty-cart" css="text-align: center;">
            Your cart is empty.
          </p>
          <Button
            as={Link}
            onClick={closeCart}
            css="width: 100%; margin-bottom: 2rem"
            to="/buy/"
          >
            Shop Sprinkler Solutions
          </Button>
        </>
      )}
      {checkout.lineItems.map(
        (lineItem: LineItem) =>
          lineItem && (
            <CartItem key={lineItem.id} lineItem={lineItem} />
          )
      )}

      {!isCartEmpty && (
        <footer css="margin: 2rem 0; text-align: center">
          <Flex>
            <p className="large">Subtotal</p>
            <p className="large" css="color: var(--primaryColor)">
              ${Number(checkout.subtotalPrice.amount).toFixed(2)}
            </p>
          </Flex>

          <Button
            data-testid="cart-checkout-button"
            css="width: 100%; margin-bottom: 2rem"
            onClick={openCheckout}
            disabled={isCheckoutLoading}
          >
            Checkout
          </Button>
          <p className="subtext">
            Shipping, taxes and discount codes are added at checkout.
          </p>
        </footer>
      )}
    </div>
  );
}

type CartModalProps = {
  isCartOpen: boolean
  closeCart: () => void
  openCheckout: () => void
  isCheckoutLoading: boolean
  checkout: Cart
}

const CartModal = ({
  isCartOpen,
  closeCart,
  checkout,
  isCheckoutLoading,
  openCheckout,
}: CartModalProps) => {
  useScrollFreeze()
  const { loggedIn } = useContext(AuthContext);
  const pointerEvents = isCartOpen ? `all` : `none`
  const isCartEmpty = checkout.lineItems.length < 1
  return (
    <>
      <ModalWrapper>
        <Transport
          initial={{ right: -500 }}
          animate={{ right: 0 }}
          exit={{ right: -500 }}
          transition={{ duration: 0.3 }}
          css="position: relative;"
        >
          <AnimatePresence>
            {isCheckoutLoading && (
              <LightBar
                style={{
                  position: "absolute",
                  top: 0,
                  left: "1rem",
                }}
              />
            )}
          </AnimatePresence>
          <CartWrapper isCartOpen={isCartOpen} style={{ pointerEvents }}>
            <div data-testid="cart">
              <Flex css="margin-bottom: 2rem">
                <h4
                  css="color: var(--primaryColor); margin: 0"
                  data-testid="cart-title"
                >
                  Your Cart
                </h4>
                <button onClick={closeCart}>
                  <Icon name="close" color="var(--primaryColor)" />
                </button>
              </Flex>
              {
                loggedIn
                  ? <CartContent
                    isCartEmpty={isCartEmpty}
                    checkout={checkout}
                    closeCart={closeCart}
                    isCheckoutLoading={isCheckoutLoading}
                    openCheckout={openCheckout}
                  />
                  : <NotLoggedIn closeCart={closeCart} />
              }
            </div>
          </CartWrapper>
        </Transport>
      </ModalWrapper>

      <Background
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        onClick={closeCart}
      />
    </>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  pointer-events: none;
  z-index: calc(var(--highestLevel) + 1);
  ${below.xSmall`
      left: 0;
  `}
`

const Background = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: all;
  background: linear-gradient(
    142.24deg,
    rgba(0, 40, 58, 0.6) 3.62%,
    rgba(0, 167, 225, 0.6) 100%
  );
  width: 100vw;
  height: 100vh;
  z-index: 11;
`

const CartWrapper = styled.div`
  padding: var(--basePadding);
  background: var(--white);
  height: 100vh;
  overflow-y: scroll;
  width: 42.2rem;
  margin-left: 1rem;
  box-shadow: var(--left-elevation);
  z-index: var(--highestLevel);
  ${below.xSmall`
    width: 100%;
    margin-left: 0;
  `};
`

const Transport = styled(motion.div)`
  width: 100%;
`
