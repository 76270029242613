import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Bar = styled(motion.div)`
  animation: glow 5s ease infinite;
  background: linear-gradient(
    270deg,
    #1f9ed4,
    #2aadda,
    #5ad4ff,
    #6ad2ed,
    #5ad4ff,
    #2aadda,
    #1f9ed4
  );
  background-size: 200% 1rem;
  box-shadow: 0 0 0 0 white, 0 8px 18px rgba(0, 0, 0, 0.2);
  height: 1rem;
  width: 100%;
  @keyframes glow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const LightBar = ({ style = {} }: { style?: object }) => (
  <Bar
    style={style}
    initial={{ top: -10 }}
    animate={{ top: 0 }}
    exit={{ top: -10 }}
  />
)

export default LightBar
