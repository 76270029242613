export const stripNonAlphanumeric = (string: string) => {
  return string.replace(/[^a-zA-Z0-9]/g, "")
}

export const isValidEmail = (email: string) => {
  const pattern = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  )
  return pattern.test(email)
}

export const scrollTo = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    window.scrollTo({
      behavior: "smooth",
      top: element.offsetTop - 100,
      left: element.offsetLeft,
    })
  }
}