import styled from "styled-components";

export const Alert = styled.div`
  padding: 20px 10px;
  background: var(--orange);
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  a {
    color: white;
  }
`