import React, { SyntheticEvent } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { below } from "../../styles"
import Icon from "../Icon"
import DropdownMenu from "./DropdownMenu"
import { scrollTo } from "../../utils/helpers"
import { IGatsbyImageData } from "gatsby-plugin-image"

export type Item = {
  name: string
  image: IGatsbyImageData
  link: string
}

export type LinkType = {
  link: string
  title: string
  isExternalLink?: boolean
  // id of section you want to scroll to
  scrollTo?: string
  items?: Item[]
}

type Props = {
  links: LinkType[]
}

const variants = {
  rest: {
    height: 0,
    visibility: "hidden",
    opacity: 0,
    y: -20,
  },
  hover: {
    opacity: 1,
    y: 0,
    height: "auto",
    visibility: "visible",
    transition: {
      duration: 0.3,
    },
  },
}

const NavLink = ({ link }: { link: LinkType }) => {
  if (link.isExternalLink) {
    return (
      <li className="menu-item">
        <a href={link.link}>{link.title}</a>
      </li>
    )
  }

  if (link.scrollTo) {
    const handleScroll = (event: SyntheticEvent) => {
      event.preventDefault();
      scrollTo(link.scrollTo as string)
    }

    return (
      <li className="menu-item">
        <a href={link.link} onClick={handleScroll}>
          {link.title}
        </a>
      </li>
    )
  }

  return (
    <motion.li
      className={`menu-item ${link.items ? `has-dropdown` : ""}`}
      initial="rest"
      whileHover="hover"
    >
      <Link
        to={link.link}
        aria-label={link.title}
        aria-haspopup={link.items}
      >
        {link.title}
      </Link>

      {link.items && (
        <>
          {" "}
          <Icon
            name="caret"
            color="var(--primaryColor)"
            className="caret-icon"
            style={{ transition: "0.3s ease-in-out transform" }}
          />
          <DropdownMenu items={link.items} variants={variants} />
        </>
      )}
    </motion.li>
  )
}

const MainNav = ({ links }: Props) => {
  return (
    <Nav>
      <ul>
        {links.map(link => <NavLink link={link} key={link.link} />)}
      </ul>
    </Nav>
  )
}

export default MainNav

const Nav = styled.nav`
  .menu-item {
    border-bottom: 2px solid transparent;
    display: inline-block;
    position: relative;
    margin-left: 20px;
    &.has-dropdown {
      cursor: pointer;
      &:focus-within .dropdown-menu {
        visibility: visible;
        opacity: 1;
        display: block;
        a {
          outline: none;
        }
      }
      &:hover {
        transition: 0.5s ease-in-out all;
        border-bottom: 2px solid var(--primaryColor);
        .caret-icon {
          transform: rotate(-180deg);
        }
        .dropdown-menu {
          display: block;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  ${below.medium`
    display: none;
  `};
`
