import React, { useState } from "react"
import styled from "styled-components"
import { media, below, Card } from "../../styles"
import { Link } from "gatsby"
import Accordion from "../Accordion"
import Modal from "../Modal"
import Rebate from "../Rebate"

type NavItem = {
  name: string
  link: string
  isExternalLink?: boolean
  isRebate?: boolean
}

type Nav = {
  title: string
  items: NavItem[]
}

type Props = {
  link: Nav
}

const MobileFooterNav = ({ link }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)

  return (
    <FooterNavWrapper>
      <div className="item-list">
        <h6>{link.title}</h6>
        <ul>
          {link.items.map(item => (
            <li key={item.name}>
              {item.isRebate ? (
                <>
                  <button
                    onClick={() => setIsActive(true)}
                    css={`
                      &:hover {
                        color: var(--primaryBlue);
                      }
                    `}
                  >
                    {item.name}
                  </button>
                  <Modal
                    isActive={isActive}
                    closeAction={() => setIsActive(false)}
                    maxWidth="95%"
                  >
                    <Card css="background: var(--white)">
                      <Rebate />
                    </Card>
                  </Modal>
                </>
              ) : (
                <>
                  {item.isExternalLink ? (
                    <a href={item.link}>{item.name}</a>
                  ) : (
                    <Link to={item.link}>{item.name}</Link>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="mobile-item-list">
        <Accordion
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          items={link.items}
          title={link.title}
          isMenuOpen={isMenuOpen}
        />
      </div>
    </FooterNavWrapper>
  )
}

export default MobileFooterNav

const FooterNavWrapper = styled.div`
  h6 {
    margin: 0;
  }
  li {
    padding: 1rem 0;
    a {
      padding: 0;
      display: inline-block;
    }
  }
  ${media.medium`
    .mobile-item-list {
      display: none;
    }
  `}
  ${below.medium`
    .item-list {
      display: none;
    }
  `};
`
