import { useEffect } from "react"

export default function useScrollToTop(scrollTrigger: any) {
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
      left: 0,
    })
  }, [scrollTrigger])
}
