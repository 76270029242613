import React from "react"
import Icon from "./Icon"

const SocialMedia = () => {
  return (
    <div
      className="social-media"
      css={`
        a:not(:first-child) {
          margin-left: 1rem;
        }
      `}
    >
      <a
        href="https://www.facebook.com/groups/rachiopro"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on Facebook"
      >
        <Icon name="facebook" />
      </a>

      <a
        href="https://www.instagram.com/rachiopros/"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on Instagram"
      >
        <Icon name="instagram" />
      </a>
    </div>
  )
}

export default SocialMedia
