import styled, { css } from "styled-components"
import { media, below } from "./breakpoints"
// Grids & Utilities
// 🔒 Not configurable
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Used anytime you need 2 columns inside of a layout
// Cards, Images, nearly anything
// * scalign: any justify-content property value = 'space-between'
export const Flex = styled.div`
  display: flex;
  margin: 0 auto;
  > *:last-child {
    margin-left: 20px;
  }
  justify-content: ${({ scalign }: { scalign?: string }) =>
    scalign || "space-between"};
`

// Used anytime you need a grid of anything.
// Cards, Images, nearly anything
// * cols: [1,2,3], 1
export const Grid = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin: 0 auto;
  ${({ cols = [] }) => {
    if (typeof cols === "object") {
      return cols.map((_, index: number) => {
        // This nifty map goes over each col and adds style to a breakpoint
        const key = Object.keys(media)[index]
        return media[key]`
         grid-template-columns: repeat(${cols[index]}, 1fr);
      `
      })
    }
    return css`
      grid-template-columns: repeat(${cols}, 1fr);
    `
  }}
`

// Used anytime you need the standard margin around something.
// A group of buttons, an image, anything
export const Items = styled.div`
  margin: var(--margins);
`

// Used anytime you need section padding.
export const Container = styled.div`
  padding: var(--topSectionPadding) 0 var(--bottomSectionPadding) 0;
`

// Used anytime you need standard padding with a border.
export const Card = styled.div`
  border-radius: var(--cardRadius);
  border: 1px solid var(--lightestGray);
  padding: var(--cardPadding);
  &.selected {
    border: 2px solid var(--primaryBlue);
  }
`

// Used anytime we need padding and max width in a layout
// if bg has color, needs to be wrapped in a div with a background color
//  * width: "tight" or width in px
export const Wrapper = styled.div`
  padding: 0 var(--basePadding);
  margin: 0 auto;
  max-width: 1146px;
  ${media.large`
    max-width: ${({ width }: { width: string }) =>
      width === "tight" ? "940px" : width};
  `}
`

// Standard table style
export const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;
  overflow-x: auto;
  margin: 0 auto;
  padding: 0;
  tr {
    th {
      background: var(--lightesterGray);
      border-bottom: 1px solid var(--lighterGray);
      padding: 2rem;
      text-align: left;
      vertical-align: top;
      font-weight: normal;
      p {
        display: inline;
        margin: 0;
        ${below.medium`
          display: block;
        `}
      }
    }
    td {
      &:nth-child(even) {
        box-shadow: var(--elevation-0);
      }
      padding: 2rem 8rem 2rem 10rem;
      ${below.medium`
        padding: 1rem 1rem 1rem 3rem;
      `}
      p {
        margin: 0;
      }
    }
  }
  thead {
    th {
      text-align: center;
      padding: 2rem 1rem;
      &:first-child {
        font-weight: bold;
      }
      &:nth-child(2) {
        box-shadow: var(--elevation-0);
      }
      &:not(:first-child) {
        background: var(--white);
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
      }
      &:nth-child(even) {
        background: var(--lightesterGray);
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 2rem 1rem;
        text-align: center;
        a {
          margin-left: 2rem;
        }
        &:nth-child(even) {
          box-shadow: none;
        }
      }
    }
  }
  ${below.medium`
    display: block;

    tr {
      th {
      min-width: 250px;
      }
      td {
        min-width: 120px;
      }
    }
  `};
`
