import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { media } from "../../styles"
import SocialMedia from "../SocialMedia"
import Logo from "../Logo"
import { Button } from "../Button"
import FooterNavItem from "./FooterNavItem"

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterGrid>
        <div>
          <Logo />
          <p>
            Rachio's mission is to advance the smart home outside, through conservation and connection.
          </p>
          <Button
            as={Link}
            to="/signup/"
            css="margin: var(--margins)"
            size="small"
          >
            Become a Rachio Pro
          </Button>
        </div>
        <nav>
          {links.map(link => (
            <FooterNavItem link={link} key={link.title} />
          ))}
        </nav>
      </FooterGrid>
      <FooterBottom>
        <SocialMedia />
        <div>
          <p className="copyright-text subtext">
            &copy; {new Date().getFullYear()} Rachio inc. <span>| </span>
          </p>{" "}
          <a href="https://www.rachio.com/privacy-policy">
            <p className="subtext">Privacy Policy</p>
          </a>{" "}
          |{" "}
          <a href="#" onClick={(e) => {
            e.preventDefault();
            window?.UC_UI?.showSecondLayer()
          }}>
            <p className="subtext">Privacy Settings</p>
          </a>{" "}
          |{" "}
          <a href="https://rachio.com/privacy-policy-california">
            <p className="subtext">California Privacy Policy</p>
          </a>{" "}
          |{" "}
          <a href="https://www.rachio.com/terms">
            <p className="subtext">Terms &amp; Conditions</p>
          </a>{" "}
          |{" "}
          <a href="https://www.rachio.com/warranty">
            <p className="subtext">Warranty</p>
          </a>
        </div>
      </FooterBottom>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.footer`
  margin: 0 auto;
  padding: 0 3rem;
  ${media.medium`
  padding: 0 var(--headerPadding);

    .side-nav a {
      display: none;
    }
  `};
`

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: var(--margins);
  ${media.medium`
    grid-template-columns: 1fr 3fr;
    nav {
      > div {
        margin-left: 8rem;
      }
      display: flex;
    }
  `}
`

const FooterBottom = styled.div`
  padding-bottom: 3rem;
  .copyright-text {
    display: block;
    span {
      display: none;
    }
  }
  .social-media {
    margin: var(--basePadding) 0;
  }
  ${media.medium`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .copyright-text {
     display: inline-block;
     span {
       display: inline-block;
     }
    }
    .social-media {
      margin: 0;
    }
  `}
`

const links = [
  // {
  //   title: "Products & Solutions",
  //   items: [
  //     {
  //       name: "Rachio 3",
  //       link: "/rachio-3/",
  //     },
  //     {
  //       name: "Compare",
  //       link: "/compare/",
  //     },
  //   ],
  // },
  {
    title: "Support",
    items: [
      {
        name: "Pro Support",
        link: "/support/",
      },
      {
        isExternalLink: true,
        name: "Rachio Status Page",
        link: "https://rachio.statuspage.io/",
      },
      {
        isExternalLink: true,
        name: "Help Center",
        link: "https://support.rachio.com",
      },
      {
        isExternalLink: true,
        name: "Web App",
        link: "https://app.rach.io/login",
      },
      {
        isExternalLink: true,
        name: "Community",
        link: "https://community.rachio.com",
      },
    ],
  },
  {
    title: "Partners",
    items: [
      {
        name: "Where to Buy",
        link: "/where-to-buy/",
      },
      {
        isRebate: true,
        name: "Rebates",
      },
    ],
  },
  {
    title: "Contact (Rachio Pros only)",
    items: [
      {
        name: "prosupport@rachio.com",
        isExternalLink: true,
        link: "mailto:prosupport@rachio.com",
      },
      {
        name: "Pro Instagram",
        isExternalLink: true,
        link: "https://www.instagram.com/rachiopros/",
      },
      {
        name: "Pro Facebook",
        isExternalLink: true,
        link: "https://www.facebook.com/groups/rachiopro",
      },
    ],
  },
]
