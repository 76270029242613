import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { below, Flex } from "../../styles"
import { useScrollFreeze } from "../../hooks"
import Icon from "../Icon"
import MobileNav from "./MobileNav"
import Portal from "../Portal"

type LinkType = {
  link: string
  title: string
}

type Props = {
  links: LinkType[]
}

const MobileMenu = ({ links }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
  const closeMenu = () => setIsMenuOpen(false)

  return (
    <>
      <MenuIcon onClick={toggleMenu}>
        <Icon name="hamburger" />
      </MenuIcon>
      <Portal>
        <AnimatePresence>
          {isMenuOpen && (
            <MenuModal
              isMenuOpen={isMenuOpen}
              closeMenu={closeMenu}
              links={links}
            />
          )}
        </AnimatePresence>
      </Portal>
    </>
  )
}

export default MobileMenu

type MenuModalProps = {
  isMenuOpen: boolean
  closeMenu: () => void
  links: LinkType[]
}

const MenuModal = ({ isMenuOpen, closeMenu, links }: MenuModalProps) => {
  useScrollFreeze()
  const pointerEvents = isMenuOpen ? `all` : `none`

  return (
    <>
      <ModalWrapper>
        <Transport
          initial={{ right: 400 }}
          animate={{ right: 0 }}
          exit={{ right: 400 }}
          transition={{ duration: 0.3 }}
          css="position: relative;"
        >
          <MenuWrapper
            isMenuOpen={isMenuOpen}
            style={{ pointerEvents }}
            data-testid="mobile-menu"
          >
            <Flex css="padding: 3rem 0; border-bottom: 1px solid var(--lightestGray)">
              <h4
                css="color: var(--primaryColor); margin: 0;
"
              >
                Choose a Category
              </h4>
              <button onClick={closeMenu}>
                <Icon name="close" color="var(--primaryColor)" />
              </button>
            </Flex>
            <MobileNav links={links} closeMenu={closeMenu} />
          </MenuWrapper>
        </Transport>
      </ModalWrapper>
      <Background
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        onClick={closeMenu}
      />
    </>
  )
}

const MenuIcon = styled.button`
  display: none;
  margin-top: 5px;
  ${below.medium`
    display: block;
  `};
`

const MenuWrapper = styled.div`
  padding: var(--basePadding);
  background: var(--white);
  height: 100vh;
  width: 90vw;
  max-width: 37.5rem;
  overflow-y: scroll;
  margin-right: 1rem;
  box-shadow: var(--right-elevation);
  z-index: var(--highestLevel);
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  pointer-events: none;
  z-index: var(--highestLevel);
`

const Background = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: all;
  background: linear-gradient(
    142.24deg,
    rgba(0, 40, 58, 0.6) 3.62%,
    rgba(0, 167, 225, 0.6) 100%
  );
  width: 100vw;
  height: 100vh;
  z-index: 11;
`

const Transport = styled(motion.div)`
  width: 100%;
`
