import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import MainNav, { LinkType } from "./MainNav"
import { AnimatePresence } from "framer-motion"
import Logo from "../Logo"
import LightBar from "../LightBar"
import MobileMenu from "./MobileMenu"
import { graphql, useStaticQuery } from "gatsby"
import { useLoad } from "../../global"
import { below } from "../../styles"
import CartIcon from "../shopify/CartIcon"

const Header = () => {
  const { isLoading } = useLoad()

  const {
    r3Thumbnail,
    stackedControllerThumbnail,
  } = useStaticQuery(graphql`
    query {
      r3Thumbnail: file(relativePath: { eq: "products/r3/r3-front.png" }) {
        ...fixedImage94
      }
      stackedControllerThumbnail: file(
        relativePath: { eq: "products/stacked-controllers.png" }
      ) {
        ...fixedImage94
      }
    }
  `)

  const links: LinkType[] = [
    {
      link: "/pro-rewards/",
      title: "Pro Rewards"
    },
    {
      link: "/support/",
      title: "Pro Support",
      // items: [
      //   {
      //     name: "Pro Support",
      //     link: "/support/",
      //   },
      //   {
      //     isExternalLink: true,
      //     name: "Pro Instagram",
      //     link: "https://www.instagram.com/rachiopros/",
      //   },
      //   {
      //     isExternalLink: true,
      //     name: "Pro Facebook",
      //     link: "https://www.facebook.com/groups/rachiopro",
      //   },
      // ],
    },
    {
      link: "/where-to-buy/",
      title: "Where to Buy"
    },
    {
      link: "/rewards-credit/",
      title: "Pro Rewards Credit for Distribution"
    },
    
  ]
  return (
    <>
      <AnimatePresence>
        {isLoading && (
          <LightBar
            style={{
              position: "fixed",
              top: 0,
              zIndex: "101",
            }}
          />
        )}
      </AnimatePresence>
      <HeaderWrapper>
        <InnerHeader>
          <MobileMenu links={links} />
          <div className="nav-wrapper">
            <h1 className="logo">
              <Link to="/">
                <Logo />
              </Link>
            </h1>
            <MainNav links={links} />
          </div>
          <CartIcon />
        </InnerHeader>
      </HeaderWrapper>
    </>
  )
}

export default Header

const HeaderWrapper = styled.header`
  top: 0;
  width: 100%;
  height: 105px;
  background: var(--white);
  z-index: var(--headerLevel);
  position: sticky;
  box-shadow: var(--elevation-2);
  ${below.medium`
    height: 70px;
  `}
`

const InnerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--headerPadding);
  margin: 0 auto;
  height: 100%;
  .logo {
    font-size: var(--baseFontSize);
    margin: 0;
    flex-shrink: 1;
    a {
      display: flex;
      align-items: center;
      background: none;
      svg {
        margin-right: 10px;
      }
    }
  }
  .nav-wrapper {
    display: flex;
    align-items: center;
  }
  ${below.medium`
    padding: 0 3rem;
    .side-nav a {
      display: none;
    }
    .logo a svg {
      margin-right: 0;
    }
  `};
`
