import styled, { css } from "styled-components"
import { below } from "../styles"

const transparentButtonStyle = css`
  background: transparent;
  border: 2px solid var(--white);
  color: var(--white);
  box-shadow: none;
  &:hover {
    background: var(--white);
    color: var(--primaryColor);
  }
`

const outlineButtonStyle = css`
  background: transparent;
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  box-shadow: none;
  &:hover {
    background: var(--primaryColor);
    color: var(--white);
  }
`

const whiteButtonStyle = css`
  color: var(--primaryColor);
  background: var(--white);
  border: 2px solid var(--white);
  box-shadow: none;
  &:hover {
    background: none;
    color: var(--white);
    border-color: var(--white);
  }
`

const blueButtonStyle = css`
  background: var(--primaryColor);
  color: var(--white);
  border: 2px solid var(--primaryColor);
  &:hover {
    background: var(--darkestBlue);
    border-color: var(--darkestBlue);
    color: var(--white);
    box-shadow: var(--elevation-2);
  }
`

const orangeButtonStyle = css`
  background: var(--orange);
  color: var(--white);
  border: 2px solid var(--orange);
  &:hover {
    background: var(--orange);
    border-color: var(--orange);
    color: var(--white);
    box-shadow: var(--elevation-2);
  }
`

export const baseButtonStyle = css`
  cursor: pointer;
  position: relative;
  min-width: 20rem;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  border-radius: var(--buttonRadius);
  font-size: var(--baseFontSize);
  font-family: var(--headingFont);
  padding: 1.8rem 2.3rem;
  transition: 0.3s ease all;
  box-shadow: var(--elevation-1);
  &:disabled {
    background: var(--lighterGray);
    border-color: var(--lighterGray);
    color: var(--white);
    box-shadow: var(--elevation-1);
    cursor: auto;
    &:hover {
      background: var(--lighterGray);
      border-color: var(--lighterGray);
      box-shadow: var(--elevation-1);
      color: var(--white);
    }
  }
  & + & {
    margin-left: 20px;
  }
  ${below.xSmall`
    display: block;
    padding: 1.3rem 2.3rem 1.2rem;
    max-width: 100%;
    & + & {
      margin: 20px 0 0 0;
    }
  `}
`

export const smallButtonStyle = css`
  padding: 1.3rem 2.3rem 1.2rem;
  font-size: var(--smallFontSize);
  min-width: 0;
`

export const Button = styled.button`
  ${baseButtonStyle};
  ${({ size }: { size?: string }) => size === "small" && smallButtonStyle};
  ${({ color }: { color?: string }) => {
    if (color === "transparent") return transparentButtonStyle
    if (color === "white") return whiteButtonStyle
    if (color === "outline") return outlineButtonStyle
    if (color === "orange") return orangeButtonStyle
    return blueButtonStyle
  }};
`

export const TextButton = styled.button`
  border-radius: var(--buttonRadius);
  color: var(--primaryColor);
  display: inline-block;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: 500;
  transition: 0.3s ease all;
  padding: var(--smallBaseMargin);
  &:hover {
    background: var(--lightesterGray);
  }
  &:disabled {
    background: var(--lighterGray);
    color: var(--white);
    cursor: auto;
    &:hover {
      background: var(--lighterGray);
      color: var(--white);
    }
  }
  ${below.small`
    padding: var(--smallBaseMargin);
  `}
`

// Button used to skip to content for accessibility
export const SkipLink = styled(Button)`
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: white;
  border: 2px solid var(--red);
  background: var(--red);
  &:active,
  &:focus,
  &:hover {
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: auto;
    height: auto;
    overflow: visible;
  }
`
