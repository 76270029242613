import React, {
  FC,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react"
import Cookies from "js-cookie"
import { LineItem as CartLineItem, Cart } from "shopify-buy"
import { client } from "../lib/shopify"
import { useCookie } from "../hooks"

type LineItem = {
  variantId: string
  name: string
  price: string
  quantity: number
}

export const initialCheckoutValues = {
  cartQuantity: 0,
  addVariantToCart: (_: string, __: string) => {},
  setIsCartOpen: (_: boolean) => {},
  checkout: { lineItems: [] },
  updateQuantityInCart: (_: string, __: string) => {},
  removeLineItemInCart: (_: string) => {},
}

export const CheckoutContext = createContext(initialCheckoutValues)

export const CheckoutProvider: FC = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [checkout, setCheckout] = useState({ lineItems: [] })
  const [cartId, setCartId] = useCookie({ key: "proCartId", expires: 1 })
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

  const createCheckout = async () => {
    setIsCheckoutLoading(true)
    try {
      const checkoutCart: Cart = await client.checkout.create()
      setCartId(checkoutCart.id)
      setCheckout(checkoutCart)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const getCheckout = async () => {
    if (cartId) {
      try {
        const checkoutCart = await client.checkout.fetch(cartId)
        if (checkoutCart?.completedAt || checkoutCart === null) {
          // If checkout was complete then reset cart cookies and make new checkout
          Cookies.remove("proCartId")
          Cookies.remove("proDiscountId")
          return createCheckout()
        }
        return setCheckout(checkoutCart)
      } catch (e) {
        console.log(e)
      }
    }
    return createCheckout()
  }

  useEffect(() => {
    getCheckout()
  }, [])

  const addVariantToCart = async (variantId: string, quantity: string) => {
    try {
      setIsCheckoutLoading(true)
      if (!isCartOpen) {
        setIsCartOpen(true)
      }
      const lineItemsToAdd = [{ variantId: window.btoa(variantId) , quantity: parseInt(quantity, 10) }];
      const result = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (error) {
      console.log(error)
      setIsCheckoutLoading(false)
    }
  }

  const updateQuantityInCart = async (lineItemId: string, quantity: string) => {
    if (parseInt(quantity, 10) <= 0) {
      return removeLineItemInCart(lineItemId)
    }

    setIsCheckoutLoading(true)
    try {
      const result = await client.checkout.updateLineItems(checkout.id, [
        { id: lineItemId, quantity: parseInt(quantity, 10) },
      ])
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const removeLineItemInCart = async (lineItemId: string) => {
    setIsCheckoutLoading(true)
    try {
      const result = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ])
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const cartQuantity = checkout.lineItems.length
    ? checkout.lineItems
        .map((lineItem: LineItem) => lineItem.quantity)
        .reduce((accumulator, quantity) => accumulator + quantity)
    : 0

  return (
    <CheckoutContext.Provider
      value={{
        client,
        cartQuantity,
        isCheckoutLoading,
        setIsCheckoutLoading,
        addVariantToCart,
        setIsCartOpen,
        checkout,
        isCartOpen,
        updateQuantityInCart,
        removeLineItemInCart,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
